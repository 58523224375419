import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, colors, space } from 'theme'
import { CONTAINER_MAX_WIDTH, SITE_LINK_PUBLISHING, SITE_LINK_BOOSTING, SITE_LINK_AI } from 'consts'
import { pxToRem } from 'helpers'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H3 } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Button from 'components/Button'

import featureImageComment from 'static/images/publish/features/comment.svg'
import featureImageFinder from 'static/images/publish/features/finder.svg'
import featureImageIntegrations from 'static/images/publish/features/integrations.svg'
import featureImageLike from 'static/images/publish/features/like.svg'
import featureImageQueue from 'static/images/publish/features/queue.svg'
import featureImageScheduling from 'static/images/publish/features/scheduling.svg'

import featureImageTimes from 'static/images/publish/features/times.svg'
import featureImageWorkflow from 'static/images/publish/features/workflow.svg'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import featureImageExt from 'static/images/publish/features/ext.svg'
import featureImageZapier from 'static/images/publish/features/zapier.svg'
import featureImageMobile from 'static/images/publish/features/mobile.svg'
import featureImageCalendar from 'static/images/publish/features/calendar.svg'
import featureImageLabel from 'static/images/publish/features/label.svg'
import featureImageAI from 'static/images/publish/features/ai.svg'
import featureImageTracking from 'static/images/publish/features/tracking.svg'
import featureImageSchedulingBulk from 'static/images/publish/features/scheduling_bulk.svg'
import { changeTextColorByMatch } from '../../helper'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledItemWrapper = styled(Flex)`
  background: ${COLOR_CONSTANTS.WHITE};
  padding: ${space.s};
  border: 1px solid ${COLOR_CONSTANTS.SALT};
  border-radius: 12px;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledButtonSelectPlan = styled(Button.Gradient)`
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  width: 215px;
  height: 100%;
`

const FEATURE_ITEMS = [
  {
    picture_url: featureImageScheduling,
    title: 'Scheduling',
    description:
      'Import and schedule multiple social posts across different profiles and networks simultaneously. Save time and aid in planning by queuing up various posts in advance.',
    link: SITE_LINK_PUBLISHING,
  },
  {
    picture_url: featureImageScheduling,
    title: 'Boosting',
    description:
      'Boost your posts across Facebook, Instagram, LinkedIn, Pinterest, TikTok and Twitter. Test different boost settings to achieve the best ROI.',

    link: SITE_LINK_BOOSTING,
  },

  {
    picture_url: featureImageAI,
    title: 'AI Assistant',
    description:
      'Generate engaging social posts and replies tailored to your target audience, in your brand voice, in seconds using Vista Social AI Assistant.',
    link: SITE_LINK_AI,
  },
  {
    picture_url: featureImageTimes,
    title: 'Optimal send times',
    description:
      'Utilize optimization algorithms to automatically schedule content to post at the times proven to generate engagement from your social audiences.',
  },
  {
    picture_url: featureImageWorkflow,
    title: 'Approval workflow',
    description:
      'Generate workflows to guide the submission, review and approval of messages to maintain oversight, safeguard brand standards and simplify collaboration.',
  },
  {
    picture_url: featureImageIntegrations,
    title: 'Media integrations',
    description:
      'Built-in media library to keep your images and videos organized. Easily upload new media and easily find great photos, gifs, and videos to share.',
  },
  {
    picture_url: featureImageFinder,
    title: 'Content finder',
    description:
      'Find timely and engaging content from across social media to supplement original content or find inspiration.',
  },

  {
    picture_url: featureImageLike,
    title: 'First like',
    description:
      'Drop a first like on your post to get more engagement on your post! It also makes your post look that much better!\n',
  },
  {
    picture_url: featureImageQueue,
    title: 'Post queues',
    description:
      'Setup predefined times of when you would like to post for each profile. This allows for lightning fast, error free scheduling.',
  },
  {
    picture_url: featureImageComment,
    title: 'First comments',
    description:
      'Kick start your engagement with up to 10 comments. Great place to keep all of your hashtags too and Twitter threads!',
  },

  {
    picture_url: featureImageCalendar,
    title: 'Shared calendar',
    description:
      'Manage individual posts and campaigns in a collaborative content calendar to improve visibility and facilitate long-term planning.',
  },
  {
    picture_url: featureImageLabel,
    title: 'Post labels',
    description:
      'Group and categorize messages based on workflow, business objectives, and marketing strategies to easily organize content and streamline performance analysis.',
  },
  {
    picture_url: featureImageExt,
    title: 'Browser extension',
    description:
      'Share excellent content as you see it, collect photos, videos, and sounds from anywhere on the web to be used in your posts.',
  },
  {
    picture_url: featureImageMobile,
    title: 'Mobile app',
    description:
      'Seamlessly manage social from one mobile app—access your content calendar, social inbox and reports from anywhere at anytime.',
  },
  {
    picture_url: featureImageZapier,
    title: 'Zapier',
    description:
      'Automate content creation and notification using Zapier and connect your Vista Social account to other apps.',
  },
  {
    picture_url: featureImageTracking,
    title: 'URL Tracking',
    description:
      'Append tracking data to links to quickly identify social traffic in Google Analytics and monitor campaigns, referral traffic, and conversions from social posts.',
  },
  {
    picture_url: featureImageSchedulingBulk,
    title: 'Bulk scheduling',
    description: 'Schedule lots of content quickly by uploading spreadsheets, images, videos, or linking an RSS feed.',
  },
]

const CoreFeaturesSection = ({ headerTitle, matches }) => {
  return (
    <Wrapper bg={COLOR_CONSTANTS.SALT}>
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        py={{ mobile: 'l', tablet: 'xxl' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <H3
          fontSize="3xl"
          fontWeight="bold"
          color={COLOR_CONSTANTS.DENIM}
          textAlign="center"
          dangerouslySetInnerHTML={{
            __html: changeTextColorByMatch({
              text: headerTitle,
              mainColor: COLOR_CONSTANTS.DENIM,
              changeColor: colors.primary,
              matches,
            }),
          }}
        />
        <Grid
          mt="xl"
          gridGap="m"
          gridTemplateColumns={{
            mobile: 'repeat(1, 1fr)',
            mobileLarge: 'repeat(2, 1fr)',
            desktop: 'repeat(4, 1fr)',
          }}
        >
          {FEATURE_ITEMS.map(({ title, description, picture_url, link }) => {
            return (
              <StyledItemWrapper flexDirection="column" key={title} width="100%" justifyContent="space-between">
                <Flex flexDirection="column">
                  <Flex alignItems="center">
                    <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                      {title}
                    </Text>
                  </Flex>
                  <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                    {description}
                  </Text>
                </Flex>
                {link ? (
                  <Flex mt="m">
                    <StyledLinkText as="a" href={link} textAlign="left">
                      <Text fontSize="m" textAlign="left" color="primary" as="span">
                        Learn more
                      </Text>
                      <LocalImage src={imageDoubleArrowRightBlue} mt="2px" height="15px" />
                    </StyledLinkText>
                  </Flex>
                ) : (
                  <Flex my="m" py="s" display={{ mobile: 'none', mobileLarge: 'flex' }} />
                )}
              </StyledItemWrapper>
            )
          })}
        </Grid>
        <Flex mt="m" justifyContent="center" height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
          <StyledButtonSelectPlan as="a" href="/pricing">
            Get started now
          </StyledButtonSelectPlan>
        </Flex>
      </Container>
    </Wrapper>
  )
}

CoreFeaturesSection.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  matches: PropTypes.array,
}

export default CoreFeaturesSection
